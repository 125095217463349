<template>
    <div class="container" style="margin 0; padding:5px;min-width:100%; overflow-x:hidden ">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header  sticky-top" style="background-color: #f7f7f7">
                            <h3 v-if="!modeUpdate">Create Order</h3>
                            <h3 v-if="modeUpdate">Update Order {{orderId}}</h3>
                        </div>
                        <div class="card-body">

                            <div class="row">
                                <div class="col-md-8">
                                    <div class="row" v-if="!vehiclePredefined">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>Odaberi vozilo <span class="text-danger">*</span></label>
                                                <select v-model="selectedItem" class="form-control" @change="handleSelectionChange">
                                                    <option v-for="item in vehicles" :key="item.id" :value="item.id">{{ item.name }} ({{ item.year }})</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <form>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="pickup_location">Pickup Location <span class="text-danger">*</span> </label>
                                                    <select id="pickup_location"  required v-model="formData.pickup_location" name="pickup_location" class="form-control">
                                                        <option value="Bar">Bar</option>
                                                        <option value="Bečići">Bečići</option>
                                                        <option value="Budva">Budva</option>
                                                        <option value="Buljarica">Buljarica</option>
                                                        <option value="Dobre Vode">Dobre Vode</option>
                                                        <option value="Herceg Nov">Herceg Novi</option>
                                                        <option value="Kotor">Kotor</option>
                                                        <option value="Krašići">Krašići</option>
                                                        <option value="Nikisc">Nikisc</option>
                                                        <option value="Perast">Perast</option>
                                                        <option value="Petrovac">Petrovac</option>
                                                        <option value="Podgorica or Podgorica airport">Podgorica or Podgorica airport</option>
                                                        <option value="Pržno">Pržno</option>
                                                        <option value="Rafailovići">Rafailovići</option>
                                                        <option value="Risan">Risan</option>
                                                        <option value="Sutomore">Sutomore</option>
                                                        <option value="Sveti Stefan">Sveti Stefan</option>
                                                        <option value="Tivat or Tivat airport">Tivat or Tivat airport</option>
                                                        <option value="Ulcinj">Ulcinj</option>
                                                    </select>
                                                    <p v-if="errors.pickup_location" class="text-danger">{{ errors.pickup_location }}</p>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Pickup Date <span class="text-danger">*</span></label>
                                                    <date-picker v-model="localStartDate" input-class="form-control" @change="handleDurationChange"></date-picker>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Pickup Time <span class="text-danger">*</span></label>
                                                        <date-picker  v-model="startTime" :format="'HH:mm'" :time-picker-options="timePickerOptions" input-class="form-control"  @change="handleDurationChange" type="time" :show-hour="true" :show-minute="true"  :show-second="false"></date-picker>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="dropoff_location">Return Location <span class="text-danger">*</span></label>
                                                    <select id="dropoff_location"  required v-model="formData.dropoff_location" name="dropoff_location"  class="form-control">
                                                        <option value="Bar">Bar</option>
                                                        <option value="Bečići">Bečići</option>
                                                        <option value="Budva">Budva</option>
                                                        <option value="Buljarica">Buljarica</option>
                                                        <option value="Dobre Vode">Dobre Vode</option>
                                                        <option value="Herceg Nov">Herceg Novi</option>
                                                        <option value="Kotor">Kotor</option>
                                                        <option value="Krašići">Krašići</option>
                                                        <option value="Nikisc">Nikisc</option>
                                                        <option value="Perast">Perast</option>
                                                        <option value="Petrovac">Petrovac</option>
                                                        <option value="Podgorica or Podgorica airport">Podgorica or Podgorica airport</option>
                                                        <option value="Pržno">Pržno</option>
                                                        <option value="Rafailovići">Rafailovići</option>
                                                        <option value="Risan">Risan</option>
                                                        <option value="Sutomore">Sutomore</option>
                                                        <option value="Sveti Stefan">Sveti Stefan</option>
                                                        <option value="Tivat or Tivat airport">Tivat or Tivat airport</option>
                                                        <option value="Ulcinj">Ulcinj</option>
                                                    </select>
                                                    <p v-if="errors.dropoff_location" class="text-danger">{{ errors.dropoff_location }}</p>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Return Date <span class="text-danger">*</span></label>
                                                    <date-picker v-model="endDate" input-class="form-control"  @change="handleDurationChange"></date-picker>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Return Time <span class="text-danger">*</span></label>
                                                    <date-picker v-model="endTime" :format="'HH:mm'" :time-picker-options="timePickerOptions"  input-class="form-control"  @change="handleDurationChange" type="time" :show-hour="true" :show-minute="true" :minute-step="5"  :show-second="false"></date-picker>
                                                </div>
                                            </div>
                                        </div>

                                        <br>
                                        <b class="card-title">Extra Addons:</b>
                                        <hr>
                                        <div class="row">
                                            <div class="col-md-12">

                                                <div class="form-check" v-for="item in extras">
                                                    <label class="form-check-label">
                                                        <div v-if="!item.isMultiQuantity">
                                                            <input type="checkbox"   class="form-check-input"
                                                                   @change="handleExtrasCheckboxChange(item.id)"
                                                                   v-model="selectedExtras[item.id]"
                                                            >
                                                            {{ item.name }} ({{ item.price }} €)
                                                        </div>
                                                        <div v-else>
                                                            <input style="position: relative;width: 40px; padding: 5px" type="number" min="0" max="item.maxQuantity" class="form-check-input"
                                                                   @change="handleExtrasQuantityChange(item.id)"
                                                                   :value="selectedExtras[item.id]"

                                                            > × {{ item.name }} ({{ item.price }} €)
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <br>
                                        <b class="card-title">Customers Details:</b>
                                        <hr>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label >Customer Name <span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control"  required v-model="formData.name" >
                                                    <p v-if="errors.name" class="text-danger">{{ errors.name }}</p>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label >Customer Email <span class="text-danger">*</span></label>
                                                    <input type="email" class="form-control" required v-model="formData.email">
                                                    <p v-if="errors.email" class="text-danger">{{ errors.email }}</p>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label >Customer Phone <span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control"  required v-model="formData.phone">
                                                    <p v-if="errors.phone" class="text-danger">{{ errors.phone }}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <br>
                                        <b class="card-title">Flight Details:</b>
                                        <hr>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label >Flight Number</label>
                                                    <input type="text" class="form-control" v-model="formData.flight_number">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label >Arriving Time</label>
                                                    <input type="text" class="form-control" v-model="formData.arrival_time">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label> Arrival airport </label>
                                                    <input type="text" class="form-control" v-model="formData.airport">
                                                </div>
                                            </div>
                                        </div>


                                        <br>
                                        <b class="card-title">Additional informations:</b>
                                        <hr>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <textarea class="form-control" rows="10" v-model="formData.additionMessage"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="formData.approved">
                                        <label class="form-check-label" for="exampleCheck1" >Zelim da potvrdim ovu narudzbu!</label>
                                    </div>
                                    <button :disabled="createOrderButtonDisabled" type="button" class="btn btn-primary" @click="saveOrder">Save</button>
                                </div>
                                <div class="col-md-4">
                                    <div class="row">
                                        <div class="jumbotron" style="width: 100%">
                                            <h3>Cijena</h3>
                                            <p>Vozilo:<strong> {{vehicleName}}</strong></p>
                                            <div v-if="spinner" class="spinner-border pull-right" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div v-else>
                                                <p v-if="duration">Duzina najma: <strong>{{duration}} dana</strong></p>
                                                <div v-else class="alert alert-danger" role="alert">
                                                    Morate odabrati period najma!
                                                </div>
                                                <p v-if="duration">Cijena vozila: <strong>{{vehiclePrice}} €</strong></p>
                                                <p v-if="duration">Cijena dodataka: <strong>{{extrasPrice}} €</strong></p>
                                                <p v-if="duration">Ukupna cijena: <strong>{{totalPrice}} €</strong></p>
                                            </div>
                                        </div>

                                    </div>
                                    <div v-if="modeUpdate" class="row">
                                        <div class="jumbotron">
                                            <h1>Brisanje ordera</h1>
                                            <p>Oprezno, jednom obrisan order se ne moze povratiti!</p>
                                            <button :disabled="deleteButtonDisabled" type="button" class="btn btn-danger btn-block" @click="deleteOrder">Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<style>

</style>
<script>
export default {
    components: {
    },
    props: {
        vehicleName: String,
        vehicleId: Number,
        startDate: Date,

        orderId: Number // ako je setovan orderId onda je update mode!!
    },
    data: function () {
        return {
            vehiclePredefined: false,
            modeUpdate:false,

            localStartDate: this.startDate,
            endDate: null,

            startTime: null,
            endTime: null,

            //all available extras
            extras : [],

            //summary template
            duration : null,
            extrasPrice: null,
            vehiclePrice: null,
            totalPrice : null,
            spinner: false,
            selectedExtras: {}, //key=>quantity

            //
            createOrderButtonDisabled: false,
            deleteButtonDisabled: false,


            formData: {
                name: null,
                email: null,
                phone: null,
                airport: null,
                flight_number: null,
                arrival_time: null,
                additionMessage: null,
                pickup_location: null,
                dropoff_location: null,
                approved: false,
            },

            errors: {},
            timePickerOptions: {start: '00:00', step:'00:30' , end: '23:30', format: 'HH:mm' },


            vehicles:[],
            selectedItem: null,
        }
    },
    created() {
        if (this.vehicleId != null){
            this.vehiclePredefined = true;
        }

        if(this.orderId){
            this.modeUpdate = true;
            this.fechOrderData(this.orderId);
        }
    },
    async mounted() {
        await this.getExtras();
        await this.getVehicles();
    },
    methods: {

        handleExtrasCheckboxChange(id){
            const isSelected = event.target.checked;
            if(!isSelected){
                delete this.selectedExtras[id];
            }else{
                this.selectedExtras[id] = isSelected ? 1 : 0;
            }
            this.handleDurationChange();
        },
        handleExtrasQuantityChange(id){
            const quantity = parseInt(event.target.value, 10);
            this.selectedExtras[id] = quantity;
            this.handleDurationChange();
        },
        validate() {
            return this.localStartDate != null && this.endDate != null && this.startTime != null && this.endTime != null;
        },
        handleSelectionChange() {
            console.log('Odabrana opcija:', this.selectedItem);
            this.vehicleId = this.selectedItem;
            this.vehicleName = this.findVehicleById(this.vehicleId).name;
            this.handleDurationChange();
        },
        findVehicleById(id) {
            return this.vehicles.find(vehicle => vehicle.id === id);
        },
        async handleDurationChange() {
            if (this.validate()){
                this.spinner = true;
                const result = await axios.post('/api/calcPrice', {
                    from: this.formatDateToMySQL(this.localStartDate) + " " + this.formatTime(this.startTime),
                    to: this.formatDateToMySQL(this.endDate) + " " + this.formatTime(this.endTime),
                    vehicleId: this.vehicleId,
                    extras: this.selectedExtras
                });
                this.duration = result.data.duration;
                this.vehiclePrice = result.data.vehiclePrice;
                this.extrasPrice = result.data.extrasPrice;
                this.totalPrice = result.data.totalPrice;
                this.spinner = false;
            }
        },
        async getExtras() {
                const result = await axios.get('/api/getExtras');
                this.extras = result.data;
                console.log(this.extras);
        },
        async getVehicles() {
            const result = await axios.get('/api/getVehicles');
            this.vehicles = result.data;
        },
        async saveOrder(){
            // Resetirajte greške prije svake validacije
            this.errors = {};

            if (this.validateForm()) {
                const that = this;
                this.createOrderButtonDisabled = true;
                const result = await axios.post('/api/saveOrder', {
                    from: this.formatDateToMySQL(this.localStartDate) + " " + this.formatTime(this.startTime),
                    to: this.formatDateToMySQL(this.endDate) + " " + this.formatTime(this.endTime),
                    vehicleId: this.vehicleId,
                    extras: that.prepareExtrasPaylaod(),
                    formData: this.formData,
                    orderId: this.orderId
                });
                this.$emit('info');
                this.createOrderButtonDisabled = false;
            }
        },
        prepareExtrasPaylaod(){
            const result = {}
            Object.keys(this.selectedExtras).forEach(key => {
                result[key] = parseInt(this.selectedExtras[key]);
            });
            return result;
        },
        async deleteOrder(){
            // Resetirajte greške prije svake validacije
            this.errors = {};
            this.deleteButtonDisabled = true;
            const result = await axios.post('/api/deleteOrder', {orderId: this.orderId});
            this.$emit('info');
            this.deleteButtonDisabled = false;
        },

         formatirajVrijeme(datum) {
            var sati = datum.getHours();
            var minute = datum.getMinutes();

            // Dodavanje nula ispred ako je sat ili minute manje od 10
            sati = (sati < 10 ? '0' : '') + sati;
            minute = (minute < 10 ? '0' : '') + minute;

            var result  =  sati + ':' + minute;

            console.log(result);
            return result;
        },

        //u slucaju da se radi o updateu, potrebno je ucitati podatke o narudzbi
        async fechOrderData(orderId) {
            const result = await axios.get('/api/getOrderData?orderId=' + orderId);
            const data = result.data.data;
            console.log(data);

            this.formData.name = data.customer.name;
            this.formData.email = data.customer.email;
            this.formData.phone = data.customer.phone;

            this.formData.pickup_location = data.pickup_location;
            this.formData.dropoff_location = data.dropoff_location;

            this.formData.airport = data.airport;
            this.formData.flight_number = data.flight_number;
            this.formData.arrival_time = data.arrival_time;
            this.formData.additionMessage = data.additionMessage;


            this.localStartDate = new Date(data.dateFrom);
            this.startTime = new Date(data.dateFrom);

            this.endDate = new Date(data.dateTo);
            this.endTime = new Date(data.dateTo);

            this.vehicleId = data.vehicleId;
            this.vehicleName = data.vehicleName;

            this.selectedItem = data.vehicleId;

            this.selectedExtras = {};
            const keys = Object.keys(data.extras);

            keys.forEach(key => {
                console.log('445',key);
                this.selectedExtras[key] = data.extras[key].qty ?? 1;
            });

            this.formData.approved = data.approved;

            this.handleDurationChange();
        },

        //region Helpers
        formatTime(date) {
            // Get hours, minutes, and seconds
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');

            // Format the time as HH:MM:SS
            return `${hours}:${minutes}:${seconds}`;
        },
        formatDateToMySQL(date) {
            // Get year, month, and day
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');

            // Format the date as YYYY-MM-DD
            return `${year}-${month}-${day}`;
        },
        //endregion

        //region Validation
        validateForm() {
            let isValid = true;

            if (!this.formData.name) {
                this.errors.name = 'Ime je obavezno.';
                isValid = false;
            }
            if (!this.formData.phone) {
                this.errors.phone = 'Broj telefona je obavezno.';
                isValid = false;
            }

            if (!this.formData.email) {
                this.errors.email = 'Email je obavezan.';
                isValid = false;
            } else if (!this.isValidEmail(this.formData.email)) {
                this.errors.email = 'Unesite validan email.';
                isValid = false;
            }

            if (!this.formData.pickup_location) {
                this.errors.pickup_location = 'Lokacija preuzimanja je obavezna.';
                isValid = false;
            }

            if (!this.formData.dropoff_location) {
                this.errors.dropoff_location = 'Lokacija vraćanja je obavezna.';
                isValid = false;
            }

            if(!this.duration){
                this.errors.duration = 'Morate odabrati period najma!';
                isValid = false;
            }

            return isValid;
        },
        isValidEmail(email) {
            // Jednostavna provjera validnosti email adrese
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        }
        //endregion
    },
}
</script>
