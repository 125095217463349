<template>
    <section>
        <div class="d-flex">
            <date-range-picker
                ref="picker"
                opens="center"
                :singleDatePicker="'range'"
                :timePicker24Hour="true"
                :showWeekNumbers="true"
                :showDropdowns="true"
                :autoApply="false"
                v-model="selectedDateRange"
                :ranges="false"
                @update="handleRangeChange"
            />
            <div class="btn-group ml-2" style="gap:5px" role="group" aria-label="Basic example">
                <button @click="showMeNextPeriod(30)" type="button" class="btn btn-secondary">30 <br> >> </button>
                <button @click="showMeNextPeriod(60)" type="button" class="btn btn-secondary"> 60 <br>>> </button>
                <button @click="showMeNextPeriod(90)" type="button" class="btn btn-secondary">  90 <br>>> </button>
            </div>
        </div>

        <div class="table-container">
            <table class="callendar" style="overflow: hidden">
                <thead>
                    <tr>
                        <th>Automobil </th>
                        <th v-for="date in dates">{{date}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="vehicleOrderData in ordersData">
                        <td>
                            <div class="title">
                                <p>
                                    <strong>{{vehicleOrderData.vehicle.name}}</strong>
                                    <br>
                                    {{vehicleOrderData.vehicle.license_plate}}
                                </p>
                            </div>
                            <div class="progress">
                                <div class="progress-bar bg-danger progress-bar-striped" role="progressbar" :style="{ width: vehicleOrderData.statistics.percentOfUsage + '%' }" aria-valuenow="35" aria-valuemin="0" aria-valuemax="100">{{vehicleOrderData.statistics.percentOfUsage}}%</div>
                            </div>
                        </td>
                        <td  v-for="(item,index) in dateRanges" :class="index % 2 === 0 ? 'even' : 'od'" @click="openModal(vehicleOrderData.vehicle, item, null)">
                            <button type="button" class="btn btn-link">
                               Create Order
                            </button>
                        </td>
                        <div v-for="(item,index) in vehicleOrderData.orders"
                             @click="openUpdateModal(item.id)"
                             class="absolute-element"
                             :class="{approved: item.approved}"
                             :style="{left:calculateAbsBlokPostionLeft(item)+ 'px', width: calculateWidth(item)+ 'px'}">
                            <p style="line-height: 5px"> #{{item.id}} {{item.customer.name }} ({{item.totalPrice}}€)</p>
                            <p style="line-height: 5px">From: {{item.customer.pickup_location}} {{item.flight_number}}</p>
                            <p style="line-height: 5px">{{item.customer.email }}</p>
                            <p style="line-height: 5px">{{item.customer.phone }}</p>
                        </div>
                    </tr>
                </tbody>
            </table>
            <modal name="example" :adaptive="true"
                   height="70%"
                   width="60%">
                <div style="max-height: 70vh; overflow-y: auto;">
                    <create-order @info="handleInfoFromChild" :orderId="orderId" :vehicleName="vehicleName" :vehicleId="vehicleId" :startDate="startDate"></create-order>
                </div>
            </modal>
        </div>
    </section>
</template>

<style>
.reportrange-text{
   padding: 0.875rem 1.375rem !important;
}
</style>

<style scoped>
td:hover button {
    display: block;
    padding: 0;
    min-width: 100%;
}
td button {
    display: none;
}

.absolute-element {
    overflow: hidden;
    cursor: pointer;
    position: absolute;
    top: 0;
    padding-top: 5px;
    min-width: 20px;
    /*background-color: rgb(23, 162, 184);*/
    /*border-left: 1px solid black;*/
    /*border-right:1px solid black;*/
    background-size: 1rem 1rem;
    box-sizing: border-box;
    max-height: 100%;
    z-index: 1;
    transition:color 1s;
    text-align: center;
    opacity: 0.8;
    height: 100%;
    background-color: #e49996;

    border: 1px dashed #212121;
    /* Obrub */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);

&.approved{
        background-color: #5396c1;
         background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
     }

    p{
        font-weight: bold;
        line-height: 0;
    }
}

.absolute-element:hover {
    z-index: 2;
    opacity: 1;
}

.table-container {
    margin-top: 2px;
    width: 100%;
    min-width: 100%;
    max-height: 800px;
    overflow-x: auto;
    overflow-y: auto;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th, td {
    border: 1px solid #a0c8dc7a;
    padding: 8px;
    word-wrap: break-word;
    min-width: 80px;
    width: 80px;
    height: 80px;
    max-width: 80px;
    text-align: center;
}
.callendar tr{
    min-height: 100px;
}

th {
    background-color: #f2f2f2;
}
tr{
    position: relative;
}

 td:first-child {
    position: sticky;
    left: 0;
    z-index: 3;
    background-color: #fff; /* Dodajte boju pozadine po želji */
     width: 140px;
     min-width: 140px;
     opacity: 0.93;
}

thead:first-child {
    position: sticky;
    top: 0;
    background-color: #f2f2f2; /* Dodajte boju pozadine po želji */
    z-index: 4;
}

</style>
<script>
    import CreateOrder from "./CreateOrder";

    export default {
        components: {
            CreateOrder
        },
        data: function() {
            return {
                selectedDateRange: {
                    startDate: null,
                    endDate: null
                },
                dateRanges:[],
                ordersData:[],

                //selected td
                vehicleName: '',
                vehicleId: '',
                startDate: null,

                orderId:null, // setovan samo u slucjau klika na postojeci order, u supronom treba biti null

            }
        },
        mounted() {
            let today = new Date()
            today.setHours(0, 0, 0, 0);

            let startDate = new Date();
            startDate.setHours(0, 0, 0, 0);
            startDate.setDate(today.getDate() - 3)

            let endDate = new Date();
            endDate.setHours(23, 59, 59, 0);
            endDate.setDate(today.getDate() + 20)

            this.selectedDateRange.startDate = startDate
            this.selectedDateRange.endDate = endDate
            console.log('Component mounted.')

            this.dateRanges = this.getDaysArray(startDate, endDate);
            this.getOrdersData(startDate,endDate);
        },
        methods: {
            handleRangeChange(){
                console.log('handleRangeChange');
                this.dateRanges = this.getDaysArray( this.selectedDateRange.startDate, this.selectedDateRange.endDate);
                this.getOrdersData(this.selectedDateRange.startDate,this.selectedDateRange.endDate);
            },

            getDaysArray (start, end) {
                for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
                    arr.push(new Date(dt));
                }
                return arr;
            },
            formatirajDatum(datum) {
                const dani = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
                const mjeseci = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

                const dan = datum.getDate();
                const mjesec = mjeseci[datum.getMonth()];
                const danUzorka = dani[datum.getDay()];

                return `${dan}. ${mjesec} ${danUzorka}`;
            },
            getOrdersData(startDate, endDate){
                const startDateString = this.pretvoriUMySQLDatum(startDate);
                const endDateString = this.pretvoriUMySQLDatum(endDate);
                axios.get( `/api/orders-data?dateFrom=${startDateString}&dateTo=${endDateString}`)
                .then(response => {
                    this.ordersData = response.data.data;
                })
                .catch(error => {
                    console.log(error);
                });
            },
            pretvoriUMySQLDatum(datum) {
                const godina = datum.getFullYear();
                const mjesec = String(datum.getMonth() + 1).padStart(2, '0');
                const dan = String(datum.getDate()).padStart(2, '0');
                return `${godina}-${mjesec}-${dan}`;
            },
            razlikaUDatumimaUSatima(datum1, datum2) {
                // Razlika u milisekundama
                const razlikaMS = (datum1 - datum2);
                // Pretvaranje razlike u sate
                const razlikaSati = razlikaMS / 1000 / 60 / 60;
                return razlikaSati;
            },

            calculateAbsBlokPostionLeft($orderDate){
                const dayWidth = 80;
                const orderStartDate = new Date($orderDate.dateFrom);
                const satiOdReferentnogDatuma = this.razlikaUDatumimaUSatima(orderStartDate,this.selectedDateRange.startDate);
                const margin =  140 ;// width prve kolone za koju se sve pomjera
                const left =  (satiOdReferentnogDatuma/24) * dayWidth + margin;
                // console.log($orderDate.id,orderStartDate,satiOdReferentnogDatuma,left);
                return left;
            },
            calculateWidth(orderDate){
                const dayWidth = 80;
                const orderStartDate = new Date(orderDate.dateFrom);
                const orderEndDate = new Date(orderDate.dateTo);
                const satiNajma = Math.abs(this.razlikaUDatumimaUSatima(orderStartDate, orderEndDate));
                const width = Math.round(satiNajma/24 * dayWidth);
                // console.log(orderDate.id,width,satiNajma,orderStartDate,orderEndDate);
                return width;
            },
            openModal(vehicle, startDate) {
                this.clearChildCompParams();
                // console.log(vehicle, startDate);
                this.vehicleName = vehicle.name;
                this.startDate = startDate;
                this.vehicleId = vehicle.id;

                // Otvaranje modala
                this.$modal.show('example');
            },

            clearChildCompParams(){
                this.vehicleName = '';
                this.vehicleId = null;

                this.orderId = null;
            },

            openUpdateModal(orderId) {
                this.clearChildCompParams();
                console.log('openUpdateModal', orderId);
                this.orderId = orderId;
                // Otvaranje modala
                this.$modal.show('example');
            },

            closeModal() {
                // Zatvaranje modala
                this.$modal.hide('example');
                this.clearChildCompParams();

            },

            handleInfoFromChild(info) {
              console.log(info);
              this.closeModal();
              this.getOrdersData(    this.selectedDateRange.startDate,    this.selectedDateRange.endDate);
              this.clearChildCompParams();
            },
            showMeNextPeriod(duration){
                this.resetStartDate();
                this.setToDateFromStartDate(duration)
                this.handleRangeChange();
            },

            resetStartDate(){
                let startDate = new Date();
                startDate.setHours(0, 0, 0, 0);
                startDate.setDate(startDate.getDate() - 3)

                this.selectedDateRange.startDate = startDate;
            },

            setToDateFromStartDate(duration){
                this.selectedDateRange.endDate = new Date(this.selectedDateRange.startDate);
                this.selectedDateRange.endDate.setDate(this.selectedDateRange.startDate.getDate() + duration);
                this.handleRangeChange();
            }

        },
        computed: {
            dates() {
                return this.dateRanges.map(date => {
                    return this.formatirajDatum(date);
                })
            }
        }
    }
</script>
