<template>
    <div>
        <link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet">
        <input v-model="text" :name="name" type="hidden">
        <div ref="quillRef"></div>

        <!-- Modal -->
        <div :id="modalRef" class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1"
             aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Select Photo</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p v-if="photos.length === 0"> Galerija za ovaj entitet je trenutno prazna!</p>
                        <div class="card"
                             style="width: 25%;display: inline-block; !important;"
                             v-for="item in photos" :key="item.id">
                            <div class="card-body card-photo" :class="{ 'active': selectPhotoKey === item.id}"
                                 style="padding: 5px" v-on:click="selectPhoto(item);">
                                <img :src="'/' + item.path" class="card-img-top">
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-if="photos.length > 0" type="button" class="btn btn-primary"
                                v-on:click="insertImgTag()">
                            Use this image
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <input ref="modalTrigger" type="hidden" data-toggle="modal" :data-target="'#' + modalRef">
    </div>
</template>
<script>

import Quill from "quill";
import {forEach} from "lodash/fp/_util";

export default {
    props: ['name', 'value', 'photoGalleryUrl'],
    data() {
        return {
            text: '',
            imgList: [],
            editor: null,
            selectPhotoKey: null,
            selectPhotoPath: null,
            modalRef: null,
            photos: [],
        }
    },
    created() {
        this.modalRef = (Math.random() + 1).toString(36).substring(7);
    },
    async mounted() {
        this.text = this.value;
        if (this.photoGalleryUrl != null) {
            await this.fetchImages();
        }
        this.initQuill();
    },
    methods: {
        async fetchImages() {
            this.photos = [];
            const that = this;
            const response = await fetch(this.photoGalleryUrl);
            const data = await response.json();

            data.forEach(item => {
                that.photos.push(item);
            });
        },
        initQuill() {
            const that = this;

            const config = {
                theme: 'snow',
                modules: {
                    toolbar: {
                        container: [
                            [{'header': [1, 2, 3, 4, false]}],
                            ['bold', 'italic', 'underline'],        // toggled buttons
                            [{'list': 'ordered'}, {'list': 'bullet'}],

                            [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
                            ['link', 'image'],

                            [{'color': []}, {'background': []}],          // dropdown with defaults from theme
                            [{'align': []}],

                        ],
                        handlers: {
                            'image': function () {
                                that.fetchImages();
                                that.$refs.modalTrigger.click();
                            }
                        }
                    }
                },
            };

            const editor = new Quill(this.$refs.quillRef, config);
            editor.root.innerHTML = this.value;
            editor.on('text-change', function (delta, source) {
                that.text = editor.root.innerHTML;
            });

            this.editor = editor;
        },
        selectPhoto(item) {
            this.selectPhotoKey = item.id;
            this.selectPhotoPath = item.path;
        },
        insertImgTag() {
            const range = this.editor.getSelection();
            this.editor.insertEmbed(range.index, 'image', '/' + this.selectPhotoPath);
        }
    }
}
</script>
<template>
    <div>
        <link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet">
        <input v-model="text" :name="name" type="hidden">
        <div ref="quillRef"></div>

        <!-- Modal -->
        <div :id="modalRef" class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1"
             aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Select Photo</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p v-if="photos.length === 0"> Galerija za ovaj entitet je trenutno prazna!</p>
                        <div class="card"
                             style="width: 25%;display: inline-block; !important;"
                             v-for="item in photos" :key="item.id">
                            <div class="card-body card-photo" :class="{ 'active': selectPhotoKey === item.id}"
                                 style="padding: 5px" v-on:click="selectPhoto(item);">
                                <img :src="'/' + item.path" class="card-img-top">
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-if="photos.length > 0" type="button" class="btn btn-primary"
                                v-on:click="insertImgTag()">
                            Use this image
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <input ref="modalTrigger" type="hidden" data-toggle="modal" :data-target="'#' + modalRef">
    </div>
</template>
<style>
.card-photo.active {
    background-color: #2196f3;
}
.card-photo.active {
    background-color: #2196f3;
}

.ql-container{
    height: 100vh !important;
}

.ql-editor p {
        margin-bottom: 1.15rem!important;
        font-size: 1rem!important;
        line-height: 1.5rem!important;
    }
.ql-editor h2 {
        font-size: 1.875rem!important;
        font-family: 'Poppins', sans-serif!important;
        font-weight: 700!important;
    }
.ql-editor h3 {
        font-size: 1.5rem!important;
        line-height: 1.4!important;
        margin-top: 0!important;
        margin-bottom: .5rem!important;
    }

</style>
<script>

import Quill from "quill";
import {forEach} from "lodash/fp/_util";

export default {
    props: ['name', 'value', 'photoGalleryUrl'],
    data() {
        return {
            text: '',
            imgList: [],
            editor: null,
            selectPhotoKey: null,
            selectPhotoPath: null,
            modalRef: null,
            photos: [],
        }
    },
    created() {
        this.modalRef = (Math.random() + 1).toString(36).substring(7);
    },
    async mounted() {
        this.text = this.value;
        if (this.photoGalleryUrl != null) {
            await this.fetchImages();
        }
        this.initQuill();
    },
    methods: {
        async fetchImages() {

            this.photos = [];
            if(this.photoGalleryUrl){
                const that = this;
                const response = await fetch(this.photoGalleryUrl);
                const data = await response.json();

                data.forEach(item => {
                    that.photos.push(item);
                });
            }
        },
        initQuill() {
            const that = this;
            const config1 = {
                theme: 'snow',
                modules: {
                    toolbar: {
                        container: [
                            [{'header': [1, 2, 3, 4, false]}],
                            ['bold', 'italic', 'underline'],        // toggled buttons
                            [{'list': 'ordered'}, {'list': 'bullet'}],

                            [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
                            ['link', 'image'],

                            [{'color': []}, {'background': []}],          // dropdown with defaults from theme
                            [{'align': []}],
                        ],
                        handlers: {
                            'image': function () {
                                that.fetchImages();
                                that.$refs.modalTrigger.click();
                            }
                        }
                    },
                }
            };
            const config2 = {
                theme: 'snow',
                modules: {
                    toolbar: {
                        container: [
                            [{'header': [1, 2, 3, 4, false]}],
                            ['bold', 'italic', 'underline'],        // toggled buttons
                            [{'list': 'ordered'}, {'list': 'bullet'}],

                            [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
                            ['link'],

                            [{'color': []}, {'background': []}],          // dropdown with defaults from theme
                            [{'align': []}],
                        ],
                    },
                }
            };
            const editor = new Quill(this.$refs.quillRef, this.photoGalleryUrl ? config1 : config2);
            editor.root.innerHTML = this.value;
            editor.on('text-change', function (delta, source) {
                that.text = editor.root.innerHTML;
            });

            this.editor = editor;
        },
        selectPhoto(item) {
            this.selectPhotoKey = item.id;
            this.selectPhotoPath = item.path;
        },
        insertImgTag() {
            const range = this.editor.getSelection();
            this.editor.insertEmbed(range.index, 'image', '/' + this.selectPhotoPath);
        }
    }
}
</script>
