/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */



require('./bootstrap');

window.Vue = require('vue');

window.slugify = require('slugify');

window.Vuex = require('vuex');
//https://v3.vuex.vuejs.org/guide/#the-simplest-store

import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'; // Importirajte CSS ako je potrebno
import vmodal from 'vue-js-modal';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import DatePicker from 'vue2-datepicker';

import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css';
import 'bootstrap-datepicker';
import 'vue2-datepicker/index.css';

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))



Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('rich-text-input', require('./components/RichTextInputComponent.vue').default);
Vue.component('photo-gallery', require('./components/PhotoGalleryComponent.vue').default);
Vue.component('orders-component', require('./components/OrdersComponent').default);
Vue.component('date-range-picker', DateRangePicker);
Vue.component('create-order', require('./components/CreateOrder.vue').default);
Vue.component('vue-timepicker', VueTimepicker);
Vue.component('date-picker', DatePicker);
Vue.component('custom-date-picker', require('./components/CustomDatePicker.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


Vue.use(vmodal);

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        count: 0,
    },
    mutations: {
        increment(state) {
            state.count++
        },
    },
    actions: {}
});
try
{
    const app = new Vue({
        el: '#app',
        store: store,
    });
}catch (e) {
    console.log(e);
}

