<template>
    <section>
        <section>
            <h3>Photo gallery</h3>
            <p v-if="photos.length == 0"> Galerija za ovaj entitet je trenutno prazna!</p>
            <div class="flex-container mt-4" v-if="!fetching">
                <div class="ccard" v-for="item in this.photos" :key="item.id">
                    <div class="" style="position: relative">
                        <img :src="'/' + item.path" class="card-img-top">
                        <button type="button" class="btn btn-light" v-on:click="deletePhoto(item.id)"
                                style="position:absolute; top:0; right:0;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-trash" viewBox="0 0 16 16">
                                <path
                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path>
                                <path fill-rule="evenodd"
                                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </section>
        <section>
            <div class="row" id="drop_zone"  v-on:drop="dropHandler($event)"  v-on:dragover="dragoverHandler($event)">
                <div class="col-12">
                    <h3>Upload photo</h3>
                    <p>Drag one or more files to this <i>drop zone</i>.</p>
                    <form ref="myForm" enctype="multipart/form-data" method="post" :action="uploadUrl" style="margin-top: 30px;">
                        <div class="row" >
                            <div class="form-group col-12" >
                                <input type="hidden" name="resource" :value="resource" ref="resource">
                                <input type="hidden" name="resource_id" :value="resourceId" ref="resource_id">
                            </div>
                            <div class="col-12 d-flex flex-row bd-highlight mb-3" style="flex-wrap: wrap">
                                <div v-for="item in draggedPhotos" class="p-2 bd-highlight">
                                    <img class="img-thumbnail" :src="item" style="max-width: 100px; max-height: 100px;"/>
                                </div>
                            </div>

                            <div class="form-group col-12 ">
                                <span class="mdi mdi-text-box-outline"></span>

                                <label  for="file-upload" class="btn btn-info custom-file-upload mb-0">Choose file</label>
                                <input id="file-upload" data-preview="#preview" name="photo[]" type="file" multiple accept="image/*" ref="inputFile">
                                <button class="btn btn-primary" type="submit" :disabled='!enableUploadBtn'>Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </section>
</template>

<script>

export default {
    props: {
        'getAllPhotos': {
            type: String,
            required: true
        },
        "uploadUrl": {
            type: String,
            required: false
        },
        'resource': {
            type: String,
            required: true
        },
        'resourceId': {
            type: String,
            required: true
        }
    },
    data() {
        return {
            fileName: '',
            fileSize: 0,
            fileFormat: '',
            fileSelected: false,
            photos: [],
            fetching: false,

            draggedPhotos: []
        }
    },
    computed: {
        key() {
            return this.resource + '_' + this.resourceId;
        },
        enableUploadBtn() {
            return this.fileSelected || this.draggedPhotos.length > 0;
        }
    },
    async mounted() {
        await this.fetchImages();
        const that = this;
        this.$refs.inputFile.addEventListener('change', function (e) {
            if (e.target.files.length > 0) {
                for (let i = 0; i < e.target.files.length; i++) {
                    const file = e.target.files[i];
                    if (file && file.type.startsWith("image/")) { // Proveri da li je fajl slika
                        const reader = new FileReader();
                        reader.onload = (event) => {
                            that.draggedPhotos.push(event.target.result);
                        };
                        reader.readAsDataURL(file); // Učitaj sadržaj fajla kao Data URL
                    } else {
                        console.log("Izabrani fajl nije slika.");
                    }
                }
            }
        });
    },
    methods: {
        uploadPhotos(){
            debugger;
            const form =this.$refs.myForm;
            const formData = new FormData(form);
            formData.append('resource ',    this.$refs.resource.value);
            formData.append('resource_id',  this.$refs.resource_id.value);
            formData.append('photo', this.draggedPhotos);
            fetch('/admin/gallery/uploadPhoto', {
                method: 'POST',
                body: formData,
            })
            .then((response) => response.json())
            .then((data) => console.log('Success:', data))
            .catch((error) => console.error('Error:', error));
        },

        async fetchImages() {
            this.fetching = true;
            const url = this.getAllPhotos + '?resource=' + this.resource + '&resourceId=' + this.resourceId;
            const response = await fetch(url);
            this.photos = await response.json();
            this.fetching = false;
        },
        deletePhoto(id) {
            fetch('/admin/gallery/deletePhoto/' + id).then(() => {
                this.fetchImages();
            });
        },
        dragoverHandler($event){
            console.log("File(s) in drop zone");
            $event.preventDefault();
        },
        dropHandler($event){
            console.log("File(s) dropped");
            console.log($event);

            // Prevent default behavior (Prevent file from being opened)
            $event.preventDefault();

            if ($event.dataTransfer.items) {
                const dataTransfer = new DataTransfer();
                // Use DataTransferItemList interface to access the file(s)
                [...$event.dataTransfer.items].forEach((item, i) => {
                    // Proveri da li je ubačeni element fajl
                    if (item.kind === "file") {
                        const file = item.getAsFile();
                        if (file && file.type.startsWith("image/")) { // Proveri da li je fajl slika
                            const reader = new FileReader();

                            reader.onload = (event) => {
                                this.draggedPhotos.push( event.target.result);
                            };

                            reader.readAsDataURL(file); // Učitaj sadržaj fajla kao Data URL
                        } else {
                            console.log("Izabrani fajl nije slika.");
                        }

                        dataTransfer.items.add(file);
                        this.$refs.inputFile.files = dataTransfer.files;
                    }
                });
            }
        }
    },
}
</script>

<style scoped>
.flex-container {
    display: flex;
    flex-wrap: wrap;
}
.ccard {
    flex: 0 0 45%;
    margin-right: 5px;
    margin-bottom: 5px;
}

#drop_zone{
    border: 2px dashed #d4d8dd;
    padding: 20px;
    border-radius: 5px;
}

#drop_zone:hover {
    background-color: #f1f1f1;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
</style>
