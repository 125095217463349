<template>
  <div>

    <!-- Datepicker komponenta -->
    <DatePicker
     :value="value"
      format="DD. MMM"
      valueType="M-D"
      :placeholder="'Odaberite datum'"
      type="date"
     @input="updateDate"
    />

    <!-- Dugmad za akcije -->
    <button @click="saveDate" class="btn btn-primary">Save</button>
    <button @click="cancelDate" class="btn btn-secondary">Cancel</button>

    <!-- Prikaz odabranog datuma -->
    <div v-if="savedDate">
      Sačuvan datum: {{ savedDate }}
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: { DatePicker },
    props: {
        value: {
            type: String,  // ili Date ako želite Date objekt
            default: null
        }
    },
  data() {
    return {
      selectedDate: null,
      savedDate: null
    };
  },
  methods: {
    saveDate() {
      if (this.selectedDate) {
        this.savedDate = this.selectedDate;
        alert(`Datum sačuvan: ${this.savedDate}`);
      } else {
        alert("Prvo odaberite datum.");
      }
    },
    cancelDate() {
      this.selectedDate = null;
      this.savedDate = null;
    },
      updateDate(newDate) {
          this.$emit('input', newDate);  // Emituje "input" događaj za v-model ažuriranje
      }
  }
};
</script>

<style>

   .mx-btn-current-year {
        pointer-events: none;
        cursor: default;
        display: none;
    }
</style>
